@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.cdnjs.com/css?family=Space+Mono');
@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  font-family: "Jost", sans-serif;
  font-optical-sizing: auto;

}
input {
  font-size: 16px;
}

*::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none;
}


@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}


.bgImage {
  background-image: url('/public/bg.png');
  background-color: "#26ae5f"
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-item {
  display: flex;
  align-items: center;

}


.custom-scrollbar::-webkit-scrollbar {
  width: 2px; /* Set the width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #8888883f; /* Color of the scrollbar thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Change color on hover */
}

.custom-scrollbar::-webkit-scrollbar {
  display: none;
}

.custom-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}